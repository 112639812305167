import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import AppButtons from '../components/AppButtons';
import ContactButton from '../components/ContactButton';

const BenefitsPage = () => {

  const { t } = useTranslation();

  const benefits = [
    {
      title: 'BenefitTime',
      emoji: '⌛️'
    },
    {
      title: 'BenefitEco',
      emoji: '♻️'
    },
    {
      title: 'BenefitLanguage',
      emoji: '🗣'
    },
    {
      title: 'BenefitProgress',
      emoji: '📊'
    },
    {
      title: 'BenefitEverywhere',
      emoji: '🚌'
    },
    {
      title: 'BenefitRecruiting',
      emoji: '👀'
    },
    {
      title: 'BenefitDigital',
      emoji: '📱'
    },
    {
      title: 'BenefitQuality',
      emoji: '📚'
    }
  ];

  return (
    <Layout
      currentMenuItem='benefits'
    >
      <Seo
        title={'u1Dent+ Vorteile'}
        description={t('BenefitsDescription')}
      />
      <div className='md:max-w-2xl mx-auto'>
        <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
          {benefits.map(b =>
            <div className='flex flex-row md:flex-col md:h-40 items-center md:justify-around rounded-xl bg-grey-light dark:bg-grey-dark p-4 shadow-lg'>
              <div className='flex text-2xl text-text-light dark:text-text-dark font-bold h-12 w-12 bg-text-light dark:bg-text-dark items-center justify-center rounded-full'>
                {b.emoji}
              </div>
              <div className='flex text-lg md:text-center text-text-light dark:text-text-dark font-bold pl-4 md:pb-4'>
                {t(b.title)}
              </div>
            </div>
          )}
        </div>
        <div className='pt-2'>
          <ContactButton />
        </div>
        <div className='pt-2'>
          <AppButtons
            headline={t("FreeTry")}
          />
        </div>
      </div>
    </Layout >
  )
}

export default BenefitsPage;

export const query = graphql`
        query ($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
        language
        }
      }
    }
  }
        `;